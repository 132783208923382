<div class="main-content homepage grey-background">
  <div class="container-fluid">
    <div class="mat-card m- white-background text-black-50">

      <div class="mat-card-content px-3 mt-3 py-5">

        <mat-accordion class="example-headers-align" multi >

          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title>
                {{'pages.customer.customer-order' | translate}} {{'common.upload' | translate}}
              </mat-panel-title>
            </mat-expansion-panel-header>

            <div class="row mt-3">
              <div class="col-12">
                <div class="row">
                  <div class="col-3">


                  </div>
                  <div class="col-3">

                  </div>
                  <div class="col-6 pt-4 justify-content-end text-end">
                    <button
                      type="button"
                      (click)="downloadTemplate()"
                      class="btn btn-success text-white me-2">{{'pages.audit.download-template-xlsx' | translate}}</button>

                    <button
                      type="button"
                      (click)="uploadXlsx()"
                      mat-flat-button
                      color="primary"
                      class="text-white me-2">{{'pages.audit.upload-template-xlsx' | translate}}</button>

                  </div>
                </div>
              </div>
            </div>


            <div class="row mt-3">
              <div class="col-12">
                <div class="table-responsive">
                  <table class="table table-striped table-hover">
                    <thead>
                    <tr>
                      <th class="text-nowrap">Customer ID</th>
                      <th class="text-nowrap">Customer Name</th>
                      <th class="text-nowrap">Directorate</th>
                      <th class="text-nowrap">Order Date</th>
                      <th class="text-nowrap">Delivery Date</th>
                      <th class="text-nowrap">SKU</th>
                      <th class="text-nowrap">Item Name</th>
                      <th class="text-nowrap">Unit</th>
                      <th class="text-nowrap text-end">Qty</th>
                      <th class="text-nowrap text-end">Final Qty</th>
                      <th class="text-nowrap text-end">Buy Price</th>
                      <th class="text-nowrap text-end">Standard Price</th>
                      <th class="text-nowrap text-end">Margin</th>
                      <th class="text-nowrap text-end">Sell Price</th>
                      <th class="text-nowrap text-end">Subtotal</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr *ngFor="let d of pageItems;  index as i">
                      <td class="text-nowrap">{{d.customerId}}</td>
                      <td class="text-nowrap">{{d.customerName}}</td>
                      <td class="text-nowrap">{{d.directorate}}</td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>



            <div class="row mt-3">
              <div class="col-12 justify-content-end text-end">
                <button *ngIf="audit && !audit.auditEnd"
                        (click)="finish()"
                        [disabled]="disableSubmit"
                        type="button"
                        mat-raised-button color="primary">
                  {{'pages.audit.submit' | translate}}
                </button>
              </div>
            </div>

          </mat-expansion-panel>
        </mat-accordion>

      </div>
    </div>
  </div>
</div>
