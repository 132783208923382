<div class="white-background p-5">
  <form [formGroup]="form" (submit)="save()">
    <h4 class="mat-dialog-title px-4" *ngIf="mode == 'create'">{{'pages.area.area-editor' | translate}}</h4>
    <h2 class="mat-dialog-title px-4" *ngIf="mode == 'edit' || mode == 'view'">{{'pages.area.area-editor' | translate}} #{{area.id}}</h2>
    <mat-dialog-content style="width: 600px;">

      <div class="row">
        <div class="col-12">
          <app-text-field [readonly]="readonly" [label]="'pages.area.area-name' | translate" formControlName="areaName"></app-text-field>
        </div>
      </div>

      <div class="row" *ngIf="mode === 'view'">
        <div class="col-12">
          <app-select [readonly]="readonly"
                      [label]="'pages.storage.storage-name' | translate"
                      [items]="storages"
                      [bindValue]="'id'"
                      [bindLabel]="'storageName'"
                      formControlName="storageId"
          >

          </app-select>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <app-area-field [readonly]="readonly" [label]="'pages.area.area-description' | translate" formControlName="areaDescription" [rows]="10" [cols]="10"></app-area-field>
        </div>
      </div>

      <div class="row">
        <div class="col-6">
          <app-number-field [readonly]="readonly" [label]="'pages.area.delivery-fee' | translate" formControlName="deliveryFee" ></app-number-field>
        </div>
        <div class="col-6">
          <app-number-field [readonly]="readonly" [label]="'pages.area.free-delivery-min-order' | translate" formControlName="freeDeliveryMinOrder" ></app-number-field>
        </div>
      </div>

      <div class="row mt-3">
        <div class="col-12">
          <app-upload-single
            [readonly]="readonly"
            [fileUrl]="fileUrl"
            [accept]="'image/*'"
            [label]="'pages.area.image' | translate"
            [labelButton]="'pages.area.image-button' | translate" (fileChange)="getFileChange($event)">

          </app-upload-single>
        </div>
      </div>

    </mat-dialog-content>
    <mat-dialog-actions  class="justify-content-end pe-4">
      <button *ngIf="!viewMode" mat-raised-button class="py-1" color="primary">{{'common.save' | translate}}</button>
      <button type="button" mat-dialog-close mat-stroked-button class="py-1 white-background text-black-50">{{'common.close' | translate}}</button>
    </mat-dialog-actions>
  </form>
</div>
