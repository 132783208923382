import { Component, Injector, OnInit, ViewChild } from '@angular/core';
import { BasePageComponentDirective } from '../../../../shared/base/base-page.component';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { BreadcrumbService } from '../../../../services/breadcrumb.service';
import { StockAuditService } from '../../../audit/storage-audit/services/stock-audit.service';
import { StockAuditListModel, StockAuditMasterModel } from '../../../audit/storage-audit/models/stock-audit-list.model';
import { StorageModel } from '../../../master/storage/models/storage.model';
import { ProductModel } from '../../../master/product/models/product.model';
import { MatAccordion } from '@angular/material/expansion';
import { DialogScrollComponent } from '../../../../shared/components/dialog/dialog-scroll.component';
import { AuditTemplateUploadModal } from '../../../audit/storage-audit/modals/audit-template-upload.modal';
import { REPORT_TYPES } from 'src/app/constants/common.constant';

@Component({
  selector: 'app-page-customer-order-upload',
  templateUrl: './customer-order-upload.page.html',
})
export class CustomerOrderUploadPageComponent extends BasePageComponentDirective<any> implements OnInit {
  constructor(
    injector: Injector,
    private formBuilder: FormBuilder,
    private dialog: MatDialog,
    private breadcrumbService: BreadcrumbService,
    private auditService: StockAuditService,
  ) {
    super(injector);

  }
  itemList: StockAuditListModel[] = [];
  mode = 'view';
  storage: StorageModel;
  form: FormGroup;
  audit: StockAuditMasterModel;
  disableSubmit = false;
  products: ProductModel[] = [];
  productId = 0;
  f = 0;
  @ViewChild(MatAccordion) accordion: MatAccordion;

  protected readonly REPORT_TYPES = REPORT_TYPES;

  async ngOnInit(): Promise<void> {
    // editor
    super.ngOnInit();
    this.breadcrumbService.changeMessage([
      {label: 'menu.audit.index', active: false},
      {label: 'pages.audit.storage-audit', active: false, route: '/audit/storage'},
      {label: 'common.edit', active: true, route: ''}
    ]);
  }

  async ngAfterViewInit(): Promise<any> {
    setTimeout(_ => {
      this.accordion.openAll();
    }, 1500);
  }

  query(): void {
    this.auditService.findDetailAll(this.pageQuery, this.audit.id, this.audit.storageId, this.f).then((res) => {
      this.page.emit(res);
    });
  }

  save(): void {
    const dialog = this.dialog.open(DialogScrollComponent, {
      data: {
        title: this.translateService.instant('dialog.save-message'),
        subtitle: this.translateService.instant('dialog.save-title'),
        content: `#${this.storage.storageName}` ,
        buttons: [
          {
            value: 1,
            className: 'text-red btn btn-primary',
            color: 'primary',
            focus: true,
            label: this.translateService.instant('common.save')
          },
          {value: 0, className: 'text-brand-color', label: this.translateService.instant('common.close')}
        ]
      }
    });

    dialog.afterClosed().subscribe((res) => {
      if (res === 1) {
        this.auditService.update({
          id: this.audit.id,
          storageId: this.audit.storageId,
          auditStart: this.audit.auditStart,
          auditEnd: this.audit.auditEnd,
          note: this.form.controls.note.value
        }).then(r => {
          this.snackBar.open(this.translateService.instant('dialog.save-success'), undefined, {
            verticalPosition: 'top', horizontalPosition: 'end', duration: 3000
          });
        });
      }
    });
  }

  uploadTemplate(): void {

  }

  downloadTemplate(): void {
    this.auditService.templateById(this.audit.id, this.f).then(blob => {
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'template' + '.xlsx';
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    }, error => {
      console.error('Error fetching image:', error);
    });
  }

  downloadTemplatePdf(): void {
    this.auditService.templateByIdPdf(this.audit.id, this.f).then(blob => {
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'template' + '.pdf';
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    }, error => {
      console.error('Error fetching image:', error);
    });
  }

  uploadXlsx(): void {
    const dialog = this.dialog.open(AuditTemplateUploadModal, {
      data: {
        auditId: this.audit.id,
        storageId: this.storage.id
      }
    });

    dialog.afterClosed().subscribe((res) => {
      if (res) {
        this.query();
      }
    });
  }

  fillStorage(): void {

  }

  finish(): void {
    const dialog = this.dialog.open(DialogScrollComponent, {
      data: {
        title: this.translateService.instant('dialog.save-message'),
        subtitle: this.translateService.instant('dialog.save-title'),
        content: this.translateService.instant('pages.audit.submit-confirmation') ,
        buttons: [
          {
            value: 1,
            className: 'text-red btn btn-primary',
            color: 'primary',
            focus: true,
            label: this.translateService.instant('common.save')
          },
          {value: 0, className: 'text-brand-color', label: this.translateService.instant('common.close')}
        ]
      }
    });

    dialog.afterClosed().subscribe((res) => {
      if (res === 1) {
        this.disableSubmit = true;
        this.auditService.submitSave(this.audit.id).pipe().subscribe({complete: () => {
            this.snackBar.open(this.translateService.instant('dialog.save-success'), undefined, {
              verticalPosition: 'top', horizontalPosition: 'end', duration: 3000
            });
            this.router.navigate(['/audit/storage']).then();
          }, error: (err) => {
            this.disableSubmit = false;
            console.log(err);
          }});
      }
    });
  }
}
