import { Component, OnInit, Injector, Inject, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { CRUD_MODE_EDIT, CRUD_MODE_VIEW, CRUD_MODE_CREATE } from 'src/app/constants/common.constant';
import { BaseComponentDirective } from 'src/app/shared/base/base.component';
import { DialogScrollComponent } from 'src/app/shared/components/dialog/dialog-scroll.component';
import { StorageModel } from '../../storage/models/storage.model';
import { MarginModel } from '../models/margin.model';
import { MarginService } from '../services/margin.service';
import { AreaSubModel } from '../../area-sub/models/area-sub.model';
import { AreaModel } from '../../area/models/area.model';
import { AreaService } from '../../area/services/area.service';
import { AreaSubService } from '../../area-sub/services/area-sub.service';

@Component({
  selector: 'app-modal-margin',
  templateUrl: './margin.modal.html',
})
export class MarginModal extends BaseComponentDirective implements OnInit{
  public result = new EventEmitter();
  public form: FormGroup;
  public mode = '';
  public margin: MarginModel;
  public viewMode = false;
  public storages: StorageModel[] = [];

  areas: AreaModel[] = [];
  subAreas: AreaSubModel[] = [];

  constructor(
    injector: Injector,
    public dialogRef: MatDialogRef<MarginModal>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder,
    private marginService: MarginService,
    private areaService: AreaService,
    private areaSubService: AreaSubService,
    private dialog: MatDialog
  ) {
    super(injector);
    this.form = this.formBuilder.group({
      marginName: [null, [Validators.required]],
      margin: [0, [Validators.required]],
      areaId: [null, [Validators.required]],
      subAreaId: [null, [Validators.required]],
    });
  }

  async ngOnInit(): Promise<void> {
    super.ngOnInit();
    this.margin = this.data.margin as MarginModel;
    this.mode = this.data.mode;

    this.areas = await this.areaService.findAllNoPaging();
    this.form.controls.areaId.valueChanges.subscribe(async res => {
      this.form.controls.subAreaId.setValue(null);
      this.subAreas = await this.areaSubService.findAllNoPaging(res);
    });

    switch (this.mode) {
      case CRUD_MODE_EDIT: {
        this.fillForm();
        break;
      }
      case CRUD_MODE_VIEW: {
        this.readonly = true;
        this.viewMode = true;
        this.fillForm();
        break;
      }
    }
  }

  fillForm(): void {
    this.form.setValue({
      marginName: this.margin.marginName,
      margin: this.margin.margin,
      areaId: this.margin.areaId,
      subAreaId: this.margin.subAreaId
    });
  }

  save(): void {
    if (!this.form.valid) {
      this.markFormGroupAsTouched(this.form);
      return;
    }
    const payload = {
      id: (this.mode === CRUD_MODE_EDIT) ? this.margin.id : null,
      marginName: this.form.controls.marginName.value,
      margin: this.ui.moneyFormatToNumber(this.form.controls.margin.value),
      areaId: this.form.controls.areaId.value,
      subAreaId: this.form.controls.subAreaId.value
    };

    const isCreateMode = this.mode === CRUD_MODE_CREATE;

    const dialog = this.dialog.open(DialogScrollComponent,  {
      data: {
        title: this.translateService.instant('dialog.save-message'),
        subtitle: this.translateService.instant('dialog.save-title'),
        content: (isCreateMode) ? `#${payload.marginName}` : `#${payload.id} - ${payload.marginName}`,
        buttons: [
          {value: 1, className: 'text-red btn btn-primary', color: 'primary', focus: true, label: this.translateService.instant('common.save')},
          {value: 0, className: 'text-brand-color', label: this.translateService.instant('common.close')}
        ]
      }
    });

    dialog.afterClosed().subscribe((res) => {
      if (res === 1) {
        if (isCreateMode) {
          this.marginService.save(payload).then((r) => {
            this.doAfterSave(r.id);
          });
        } else {
          this.marginService.update(payload).then((_) => {
            this.doAfterSave(payload.id);
          });
        }
      }
    });
  }

  doAfterSave(id: number): void {

    this.snackBar.open(this.translateService.instant('dialog.save-success'), undefined, {
      verticalPosition: 'top', horizontalPosition: 'end', duration: 3000
    });
    this.result.emit(1);
    this.dialogRef.close();
  }
}
