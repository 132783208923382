<div class="main-content homepage grey-background">
    <div class="container-fluid">
        <div class="mat-card m- white-background text-black-50 rounded-lg">
            <div class="mat-card-title px-2 py-3">
                <h3 class="pl-2 text-capitalize">{{mode}} {{'pages.supplier.supplier-order' | translate}}</h3>
            </div>

            <div class="mat-card-content px-3 pb-5">
                <form [formGroup]="form">
                  <div class="row mt-3">
                    <div class="col-4">
                      <app-datepicker formControlName="orderDate" [label]="'pages.supplier.order-date' | translate"></app-datepicker>
                    </div>
                    <div class="col-4">
                      <app-text-field formControlName="internalInvoiceNo" [readonly]="true" [label]="'pages.supplier.internal-invoice-no' | translate"></app-text-field>
                    </div>
                  </div>
                  <mat-accordion class="example-headers-align" multi >
                    <mat-expansion-panel>
                      <mat-expansion-panel-header>
                        <mat-panel-title>
                          {{'pages.supplier.supplier-info' | translate}}
                        </mat-panel-title>
                      </mat-expansion-panel-header>
                      <div class="row">
                        <div class="col-4">
                            <app-text-field [readonly]="readonly"
                                            [label]="'pages.supplier.supplier-name' | translate"
                                            formControlName="supplierName">
                              <div class="input-group-append" (click)="openSupplierPicker()" *ngIf="!supplierOrder || supplierOrder.status == 'draft'">
                                <span class="input-group-text" id="basic-addon2">
                                    <i class="icon-search"></i>
                                </span>
                              </div>
                            </app-text-field>
                        </div>
                        <div class="col-8">
                          <app-text-field [readonly]="true"
                                          [label]="'pages.supplier.address' | translate"
                                          formControlName="address"></app-text-field>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-4"></div>
                        <div class="col-4">
                          <app-text-field [readonly]="true"
                                          [label]="'pages.supplier.contact-person' | translate"
                                          formControlName="contactPerson"></app-text-field>
                        </div>

                        <div class="col-4">
                          <app-text-field [readonly]="true"
                                          [label]="'pages.supplier.phone-number' | translate"
                                          formControlName="phone"></app-text-field>
                        </div>

                      </div>
                    </mat-expansion-panel>
                    <mat-expansion-panel>
                      <mat-expansion-panel-header>
                        <mat-panel-title>
                          {{'pages.supplier.order-info' | translate}}
                        </mat-panel-title>
                      </mat-expansion-panel-header>
                      <div class="row">
                        <div class="col-4">
                          <app-select [readonly]="readonly"
                                      [items]="statuses"
                                      [bindLabel]="'key'"
                                      [bindValue]="'value'"
                                      [label]="'pages.supplier.status' | translate" formControlName="status"></app-select>
                        </div>
                        <div class="col-4">
                          <app-select [readonly]="true"
                                      [items]="stockStatuses"
                                      [bindLabel]="'key'"
                                      [bindValue]="'value'"
                                      [label]="'pages.supplier.stock-status' | translate" formControlName="stockStatus"></app-select>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-4" *ngIf="hideOnNew">
                          <app-datepicker formControlName="receivedDate" [label]="'pages.supplier.received-date' | translate"></app-datepicker>
                        </div>

                        <div class="col-4" *ngIf="hideOnNew && form.controls.stockedDate.value">
                          <app-datepicker formControlName="stockedDate" [label]="'pages.supplier.stocked-date' | translate"></app-datepicker>
                        </div>
                        <div class="col-4" *ngIf="hideOnNew">
                          <app-datepicker formControlName="paidDate" [label]="'pages.supplier.paid-date' | translate"></app-datepicker>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-4 pt-3">
                          <app-area-field formControlName="note" [rows]="5" [label]="'pages.supplier.note' | translate"></app-area-field>
                        </div>
                        <div class="col-4 pt-3" *ngIf="img">
                          <div class="dropzone">
                            <div class="img-preview">
                              <img [src]="img" style="height: 250px;" alt="evidence">
                            </div>
                          </div>
                          <div class="mt-2">
                            <button (click)="downloadEvidence()" type="button" class="btn btn-primary">{{'common.download' | translate}}</button>
                          </div>
                        </div>

                      </div>
                      <div class="row">
                        <div class="col-4">

                        </div>
                        <div class="col-8 pt-3 text-end">
                          <button type="button" [disabled]="disableButton || (supplierOrder && supplierOrder.status !== 'draft')" (click)="create()" mat-flat-button color="primary" class="text-white">{{'common.create' | translate}}</button>
                        </div>
                      </div>
                    </mat-expansion-panel>
                    <mat-expansion-panel>
                      <mat-expansion-panel-header>
                        <mat-panel-title>
                          {{'pages.supplier.order-details' | translate}}
                        </mat-panel-title>
                      </mat-expansion-panel-header>
                      <div class="row mt-3">
                        <div class="col-12">
                          <div class="table-responsive">
                            <table class="table table-striped table-hover">
                              <thead>
                              <tr>
                                <th class="text-nowrap text-end">{{'pages.supplier.item-id' | translate}}</th>
                                <th class="text-nowrap">{{'pages.supplier.item-name' | translate}}</th>
                                <th class="text-nowrap">{{'pages.supplier.unit' | translate}}</th>
                                <th class="text-nowrap text-end">{{'pages.supplier.current-stock' | translate}}</th>
                                <th class="text-nowrap text-end">{{'pages.supplier.last-buy-price' | translate}}</th>
                                <th class="text-nowrap text-end" *ngIf="hideOnNew">{{'pages.supplier.buy-price' | translate}}</th>
                                <th class="text-nowrap text-end">{{'pages.supplier.quantity' | translate}}</th>
                                <th class="text-nowrap text-end" *ngIf="hideOnNew">{{'pages.supplier.real-quantity' | translate}}</th>
                                <th class="text-nowrap text-end">{{'pages.supplier.subtotal' | translate}}</th>
                                <th class="text-nowrap">{{'pages.supplier.remark' | translate}}</th>
                                <th class="sticky-column text-center" style="padding-top: 25px;">{{'common.action' | translate}}</th>
                              </tr>
                              </thead>
                              <tbody>
                              <tr *ngFor="let d of orderDetails;  index as i">
                                <td class="text-nowrap text-end">{{d.itemGroupId}}</td>
                                <td class="text-nowrap">{{d.itemGroupName}}</td>
                                <td class="text-nowrap">{{d.unit}}</td>
                                <td class="text-nowrap text-end">{{d.currentStock | number: '1.0'}}</td>
                                <td class="text-nowrap text-end" *ngIf="hideOnNew">{{d.lastBuyPrice | number: '1.2-2'}}</td>
                                <td class="text-nowrap text-end">
                                  <app-number-field [disabled]="!hideOnNew || mode == 'view'"
                                                    [ngModelOptions]="{standalone: true}"
                                                    [(ngModel)]="d.buyPrice"
                                                    (valueChange)="getNewBuyPrice(d, i, $event)"
                                  ></app-number-field>
                                </td>
                                <td class="text-nowrap text-end">
                                  <app-number-field [disabled]="hideOnNew"
                                                    [ngModelOptions]="{standalone: true}"
                                                    [(ngModel)]="d.quantity"
                                                    (valueChange)="getQuantity(d, i, $event)"
                                  ></app-number-field>
                                </td>
                                <td class="text-nowrap text-end" *ngIf="hideOnNew">
                                  <app-number-field
                                    [disabled]="mode == 'view'"
                                    [ngModelOptions]="{standalone: true}"
                                    [(ngModel)]="d.realQuantity"
                                    (valueChange)="getRealQuantity(d, i, $event)"
                                  ></app-number-field>
                                </td>
                                <td class="text-nowrap text-end">{{d.subtotal | number: '1.2-2'}}</td>
                                <td class="text-nowrap text-end">{{d.remarkOnReceiving}}</td>
                                <td class="sticky-column">
                                  <div class="btn-group" role="group" aria-label="Button Action">
                                    <button [disabled]="disableButton || (supplierOrder && supplierOrder.status !== 'draft')" (click)="delete(d)" type="button"  mat-button color="warn">
                                      <mat-icon>delete</mat-icon>
                                    </button>
                                  </div>
                                </td>
                              </tr>
                              </tbody>
                              <tfoot>
                              <tr class="border-0" *ngIf="supplierOrder && (supplierOrder.status != 'draft' && supplierOrder.status != 'sent')">
                                <td colspan="8" class="text-end border-0" style=";"><b>{{'pages.supplier.tax' | translate}}</b></td>
                                <td class="text-end border-0">
                                  <app-number-field formControlName="tax" ></app-number-field>
                                </td>
                              </tr>
                              <tr class="border-0" *ngIf="supplierOrder && (supplierOrder.status != 'draft' && supplierOrder.status != 'sent')">
                                <td colspan="8" class="text-end border-0" style=";"><b>{{'pages.supplier.extra-expense' | translate}}</b></td>
                                <td class="text-end border-0">
                                  <app-number-field formControlName="otherAddition" ></app-number-field>
                                </td>
                              </tr>
                              <tr class="border-0" *ngIf="supplierOrder && (supplierOrder.status != 'draft' && supplierOrder.status != 'sent')">
                                <td colspan="8" class="text-end border-0" style=";"><b>{{'pages.supplier.other-discount' | translate}}</b></td>
                                <td class="text-end border-0">
                                  <app-number-field formControlName="otherSubtraction" ></app-number-field>
                                </td>
                              </tr>
                              <tr>
                                <td [colSpan]="supplierOrder && (supplierOrder.status != 'draft' && supplierOrder.status != 'sent') ? 8: 6" class="text-end"><b>Grand Total</b></td>
                                <td class="text-end"><b>{{total | number: '1.2-2'}}</b></td>
                                <td colspan="4"></td>
                              </tr>
                              </tfoot>
                            </table>
                          </div>
                        </div>
                      </div>

                    </mat-expansion-panel>
                  </mat-accordion>
                  <div class="row mt-3">
                    <div class="col-12 text-end justify-content-end">
                      <button *ngIf="buttonToShowUp('print')"
                              type="button"
                              mat-stroked-button
                              (click)="downloadInvoice()"
                              class="bg-info text-white me-2">{{'common.print' | translate}}</button>
                      <button
                        *ngIf="buttonToShowUp('save')"
                        mat-flat-button (click)="save()"
                        color="primary"
                        [disabled]="disableButton"
                        class="text-white me-2">{{'common.save' | translate}}</button>
                      <button
                        *ngIf="buttonToShowUp('sent')"
                        type="button"
                        [disabled]="disableButton"
                        mat-flat-button
                        [ngClass]="disableButton ? 'text-black me-2':'bg-success text-white me-2'"
                        (click)="sent()">{{'common.sent' | translate}}</button>
                      <button
                        *ngIf="buttonToShowUp('verify')"
                        type="button"
                        [disabled]="disableButton"
                        mat-flat-button
                        [ngClass]="disableButton ? 'text-black me-2':'bg-success text-white me-2'"
                        (click)="verified()">{{'common.verified' | translate}}</button>
                      <button
                        *ngIf="buttonToShowUp('paid')"
                        type="button"
                        [disabled]="disableButton"
                        mat-flat-button
                        color="primary"
                        class="text-white me-2"
                        (click)="paid()">{{'common.paid' | translate}}</button>
                      <button
                        type="button"
                        (click)="cancel()"
                        mat-stroked-button
                        color="warn"
                        class="">{{'common.cancel' | translate}}</button>
                    </div>
                  </div>

                </form>

            </div>
        </div>
    </div>
</div>
